@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

body {
  font-family: 'Work Sans', sans-serif;
}


.animate-fade-in {
  animation: fade-in 1s ease-in-out;
}

.animate-fade-in-down {
  animation: fade-in-down 1s ease-in-out;
}

/* index.css */
.card-shadow {
  box-shadow: 0 4px 20px rgba(221, 0, 119, 0.5); /* Sombra rosa */
}

.card-shadoww {
  box-shadow: 0 4px 20px #3CD500; /* Sombra rosa */
}

/* index.css */
.card-shadow::hover {
    width: 100px;
                 height: 100px;
               border:1px solid black;
               float: left;
               margin-right: 100px; }
         #box1 { box-shadow: 20px 20px }
           #box2 { box-shadow: 20px 20px 10px }
          #box3 { box-shadow: 20px 20px 10px blue 
}

.project-1 .project-image {

}

.project-2 .project-image {
  order:  1;
}

.project-3 .project-image {
 
}


